import { lazy } from 'react';

const SuperAdminOrganization = (lazy(() => import('features/organizations/container/orgSuperAdmin')));
const LicenseOrganization = (lazy(() => import('features/organizations/container/orgLicense')));
const SupportOrganization = (lazy(() => import('features/organizations/container/orgSupportExtended')));
const OrganizationIncomingLicenses = lazy(() => import('features/organizations/orgIncomingLicenses/container/orgIncomingLicenses'));
const OrganizationIncomingLicenseDetail = (lazy(() => import('features/organizations/orgIncomingLicenseDetail/container/orgIncomingLicenseDetail')));
const PhoenixIncomingLicenses = lazy(() => import('features/organizations/phoenixLicenses/container/phoenixLicenses'));
const JumpbirdIncomingLicenses = lazy(() => import('features/organizations/jumpbirdLicenses/container/jumpbirdLicenses'));

const SuperAdminOrgDetail = (lazy(() => import('features/organizationDetail/container/superAdminOrgDetail')));
const SupportAdminOrgDetail = (lazy(() => import('features/organizationDetail/container/supportOrgDetail')));
const LicenseAdminOrgDetail = (lazy(() => import('features/organizationDetail/container/licenseOrgDetail')));
const CustomerLicense = (lazy(() => import('features/customer-license/container/customerLicense')));
const LicenseModuleList = (lazy(() => import('features/licenseModule/container/moduleList')));

const Turnover = lazy(() => import('features/turnoverStatistics/container/turnover'));

const ReaList = lazy(() => import('features/rea/container/reaList'));

const ReportList = lazy(() => import('features/provision/container/report'));
const ReportDetail = (lazy(() => import('features/provision/container/reportDetail')));

const InvoiceGroupList = lazy(() => import('features/invoices/container/invoiceGroup'));
const InvoiceGroupDetail = (lazy(() => import('features/invoices/container/invoiceGroupDetail')));

const InventoriesList = (lazy(() => import('features/inventory/inventories/container/inventoriesList')));


const InventoryGroupList = lazy(() => import('features/inventory/inventoryGroup/container/inventoryGroup'));

const TaxRulesList = lazy(() => import('features/taxRules/container/taxRulesList'));
const CustomerList = lazy(() => import('features/documents/container/customerList'));
const DocumentList = lazy(() => import('features/documents/container/documentList'));

const IncomingLicenses = lazy(() => import('features/licenseService/incomingLicenses/container/incomingLicenses'));
const MachineList = lazy(() => import('features/licenseService/machineList/container/machineList'));
const AutoUpdateMachineList = lazy(() => import('features/licenseService/autoUpdateMachineList/container/machineList'));
const LicenseNumbers = (lazy(() => import('features/licenseService/licenseNumbers/container/licenseNumbers')));
const OrderCodeExtensions = lazy(() => import('features/licenseService/orderCodeExtension/container/orderCodeExtensionsList'));
const ConfigurationsList = lazy(() => import('features/licenseService/configuration/container/configuration'));
const LicenseDecoder = lazy(() => import('features/licenseService/licenseDecoder/container/licenseDecoder'));

const Dashboard = (lazy(() => import('features/dashboard/component/dashboard')));

const Users = lazy(() => import('features/users/container/users'));
const AddUser = (lazy(() => import('features/user-actions/container/addUser')));
const EditUser = (lazy(() => import('features/user-actions/container/editUser')));

const Permissions = lazy(() => import('features/permissions/container/permissions'));
const Roles = lazy(() => import('features/roles/container/roles'));

const Credential = (lazy(() => import('features/birdhub/credential/container/credential')));
const ClientsList = (lazy(() => import('features/birdhub/ordersList/container/clients')));
const OrdersList = (lazy(() => import('features/birdhub/ordersList/container/orders')));
const OrderDetail = (lazy(() => import('features/birdhub/ordersList/container/orderDetail')));

const Company = lazy(() => import('features/phoenix/company/container/company'));
const CompanyDetail = (lazy(() => import('features/phoenix/company/container/companyDetail')));

const ModuleList = (lazy(() => import('features/phoenix/module/container/moduleList')));
const PackageList = (lazy(() => import('features/phoenix/package/container/packageList')));
const ApiKeyList = lazy(() => import('features/phoenix/apiKeyList/container/apiKeyList'));

const JumpbirdCompany = lazy(() => import('features/jumpbird/company/container/company'));
const JumpbirdCompanyDetail = (lazy(() => import('features/jumpbird/company/container/companyDetail')));
const JumpbirdPackageList = (lazy(() => import('features/jumpbird/package/container/packageList')));

const TSEDashboard = lazy(() => import('features/tseDashboard/container/tseDashboard'));

const ResigoMachine = lazy(() => import('features/resigo/machine/container/machine'));
const ResigoLicense = lazy(() => import('features/resigo/license/container/license'));

const ResigoMachineDetail = (lazy(() => import('features/resigo/machine/container/machineDetail')));

const TSEDeviceList = lazy(() => import('features/tseDevice/container/tseDevices'));
const ZMList = lazy(() => import('features/tools/container/zmList'));
const OfferToolBundleGroup = lazy(() => import('features/offerTool/tools/bundleGroup/container/bundleGroup'));
const OfferToolBundles = lazy(() => import('features/offerTool/tools/bundle/container/bundles'));
const OfferToolProducts = lazy(() => import('features/offerTool/tools/products/container/products'));
const PbpwList = lazy(() => import('features/pbpw/container/pbpw'));
const TSEDeviceActivityLog = (lazy(() => import('features/tseDevice/container/tseDeviceActivityLog')));
const TSECustomerList = lazy(() => import('features/tseCustomer/container/tseCustomer'));
const TSECustomerListV2 = lazy(() => import('features/tseCustomer/container/tseCustomerV2'));
const TSECustomerDetail = (lazy(() => import('features/tseCustomer/container/tseCustomerDetail')));


export {
	SuperAdminOrganization, LicenseOrganization, SupportOrganization,

	OrganizationIncomingLicenses, OrganizationIncomingLicenseDetail, PhoenixIncomingLicenses, JumpbirdIncomingLicenses,

	SuperAdminOrgDetail, SupportAdminOrgDetail, LicenseAdminOrgDetail, CustomerLicense, LicenseModuleList,

	Turnover,

	ReaList,

	ReportList, ReportDetail,

	InvoiceGroupList, InvoiceGroupDetail, InventoryGroupList, InventoriesList, TaxRulesList, CustomerList, DocumentList,

	IncomingLicenses, MachineList, AutoUpdateMachineList, LicenseNumbers, OrderCodeExtensions, ConfigurationsList, LicenseDecoder,

	Dashboard,

	Users, AddUser, EditUser,

	Permissions, Roles,

	Credential, ClientsList, OrdersList, OrderDetail,

	Company, CompanyDetail,

	ModuleList, PackageList, ApiKeyList,

	JumpbirdCompany, JumpbirdCompanyDetail, JumpbirdPackageList,

	TSEDeviceList, TSEDeviceActivityLog, TSECustomerList, TSECustomerListV2, TSECustomerDetail,

	ResigoMachine, ResigoMachineDetail, ResigoLicense,

	ZMList,
	PbpwList,

	OfferToolBundleGroup, OfferToolBundles, OfferToolProducts,

	TSEDashboard,
}
