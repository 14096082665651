import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';

const errorMessages = {
	required: (field: string) => {
		return i18n.t(localizationConstants.requiredFieldValidation, { field: i18n.t(field) });
	},
	select: (field: string) => {
		return i18n.t(localizationConstants.fieldSelectionValidation, { field: i18n.t(field) });
	},
	exactLength: (field: string, length: number) => {
		return i18n.t(localizationConstants.exactLengthValidation, { field: i18n.t(field), length });
	},
	minLength: (field: string, length: number) => {
		return i18n.t(localizationConstants.minLengthStrValidation, { field: i18n.t(field), length });
	},
	minLengthArray: (field: string, length: number) => {
		return i18n.t(localizationConstants.minLengthArrValidation, { field: i18n.t(field), length });
	},
	maxLength: (field: string, length: number) => {
		return i18n.t(localizationConstants.maxLengthStrValidation, { field: i18n.t(field), length });
	},
	maxLengthArray: (field: string, length: number) => {
		return i18n.t(localizationConstants.maxLengthArrValidation, { field: i18n.t(field), length });
	},
	minValue: (field: string, value: number) => {
		return i18n.t(localizationConstants.minValueValidation, { field: i18n.t(field), value });
	},
	maxValue: (field: string, value: number) => {
		return i18n.t(localizationConstants.maxValueValidation, { field: i18n.t(field), value });
	},
	validType: (field: string, type: string) => {
		return i18n.t(localizationConstants.typeValidation, { field: i18n.t(field), type: i18n.t(field) });
	},
	hardwareKeyValidate: (field: string) => {
		return i18n.t(localizationConstants.hardwareKeyValidation, { field: i18n.t(field) });
	},
	passwordValidate: () => {
		return i18n.t(localizationConstants.passwordValidation);
	},
	passwordMatchValidate: () => {
		return i18n.t(localizationConstants.passwordMatchValidation);
	},
	amountValidate: (field: string) => {
		return i18n.t(localizationConstants.amountValidation, { field: i18n.t(field) });
	}
}

const onConfirmMessages = {
	confirmRestore: (field: string) => {
		return i18n.t(localizationConstants.confirmRestoreMsg, { field: i18n.t(field) });
	},
	confirmDelete: (field: string) => {
		return i18n.t(localizationConstants.confirmDeleteMsg, { field: i18n.t(field) });
	},
	confirmLogout: () => i18n.t(localizationConstants.confirmLogoutMsg),
	confirmAutoLicense: () => i18n.t(localizationConstants.confirmAutoLicense),
	confirmChangeLicenseVersion: () => i18n.t(localizationConstants.confirmChangeLicenseVersion),
	confirmChangeLockStatus: () => i18n.t(localizationConstants.confirmChangeLockStatus),
	confirmChangeMachineLockStatus: () => i18n.t(localizationConstants.confirmChangeMachineLockStatus),
	confirmMachineAutoLicense: () => i18n.t(localizationConstants.confirmMachineAutoLicense),
	confirmChangeCompanyLockStatus: () => i18n.t(localizationConstants.confirmChangeCompanyLockStatus),
	confirmChangeJumpbirdCompanyStatus: () => i18n.t(localizationConstants.confirmChangeJumpbirdCompanyStatus),
	confirmCancelSubscription: () => i18n.t(localizationConstants.confirmCancelSubscription),
	confirmSetPhoenixLicenseDateUnlimited: () => i18n.t(localizationConstants.confirmSetPhoenixLicenseDateUnlimited),
	confirmChangeActiveLicense: () => i18n.t(localizationConstants.confirmChangeActiveLicense),
	confirmDeleteLicense: () => i18n.t(localizationConstants.confirmDeleteLicense),
	confirmGetValidLicense: () => i18n.t(localizationConstants.confirmGetValidLicense),
	confirmChangeAutoUpdateMachineStatus: () => i18n.t(localizationConstants.confirmChangeAutoUpdateMachineStatus),
	confirmDeviceActivate: () => i18n.t(localizationConstants.confirmDeviceActivateMsg),
	confirmFieldDataSubmit: () => i18n.t(localizationConstants.confirmFieldSubmitMsg),
	confirmDeleteTaxRule: () => i18n.t(localizationConstants.confirmDeleteTaxRule),
	confirmDeletePartnerCommission: () => i18n.t(localizationConstants.confirmDeletePartnerCommission),
	confirmExportPartnerCSV: () => i18n.t(localizationConstants.confirmExportPartnerCSV),
	confirmDeletePartnerCreditNote: () => i18n.t(localizationConstants.confirmDeletePartnerCreditNote),
	confirmDeleteCustomerCommission: () => i18n.t(localizationConstants.confirmDeleteCustomerCommission),
	confirmRevertCustomerCommission: () => i18n.t(localizationConstants.confirmRevertCustomerCommission),
	confirmRevertCustomerCommissionStatus: () => i18n.t(localizationConstants.confirmRevertCustomerCommissionStatus),
	confirmRevertModPercentage: () => i18n.t(localizationConstants.confirmRevertModPercentage),
	confirmRevertPosPercentage: () => i18n.t(localizationConstants.confirmRevertPosPercentage),
	confirmArchiveInvoiceGroup: () => i18n.t(localizationConstants.confirmArchiveInvoiceGroupMessage),
	confirmRestoreInvoiceGroup: () => i18n.t(localizationConstants.confirmRestoreInvoiceGroupMessage),
	confirmArchiveProvisionReport: () => i18n.t(localizationConstants.confirmArchiveProvisionReportMessage),
	confirmRestoreProvisionReport: () => i18n.t(localizationConstants.confirmRestoreProvisionReportMessage),
	confirmCreateInvoiceMessage: () => i18n.t(localizationConstants.confirmCreateInvoiceMessage),
	confirmCreateInvoiceAndSendMailMessage: () => i18n.t(localizationConstants.confirmCreateInvoiceAndSendMailMessage),
	confirmDeleteInvoiceMessage: () => i18n.t(localizationConstants.confirmDeleteInvoiceMessage),
	confirmDeleteInventoryGroupMessage: () => i18n.t(localizationConstants.confirmDeleteInventoryGroupMessage),
	confirmCreateReCreateNoteMessage: (operation: string) => i18n.t(operation === 'createNote' ? localizationConstants.confirmCreateNoteMessage : localizationConstants.confirmReCreateNoteMessage),
	confirmCreateDownloadRequest: () => i18n.t(localizationConstants.confirmCreateDownloadRequest),
	confirmResetPassword: () => i18n.t(localizationConstants.confirmResetPassword),
	confirmGeneratePassword: () => i18n.t(localizationConstants.confirmGeneratePassword),
	confirmChangeUsedOrderCodeStatus: () => i18n.t(localizationConstants.confirmChangeUsedOrderCodeStatus),
}

export {
	errorMessages,
	onConfirmMessages
}
