import React from 'react';
import './emptyDataContainer.css';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from '../translate';

interface EmptyDataContainerProps {
	text?: string
}

const EmptyDataContainer: React.FC<EmptyDataContainerProps> = (props) => {
	return (
		<div className='empty-data-wrapper'>
			<Translate text={props.text || localizationConstants.noDataFoundText} />
		</div>
	)
}

export default EmptyDataContainer;
