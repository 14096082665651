import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { FormikValues } from 'formik';

import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';

import { ResetPasswordParams } from '../interface/company';
import ResetPasswordForm from '../component/resetPasswordForm';

const ResetPassword: React.FC = () => {
	const [searchParams] = useSearchParams();
	const email = searchParams.get("email") || "";
	let { token = "" } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [isSuccessfull, setIsSuccessful] = useState(false);

	const resetPassword = (values: FormikValues) => {
		setIsLoading(true);
		const data: ResetPasswordParams = {
			email: email,
			password: values.password,
			token: token,
		};
		HttpService.post(API_CONFIG.path.phoenixResetPassword, data)
			.then((response) => {
				setIsLoading(false);
				setIsSuccessful(true);
				setMessage('phoenixPasswordSuccessfullyReset');
			})
			.catch((err: Error) => {
				setIsLoading(false);
			});
	};

	return (
		<div className='gray-bg phoenix-password-wrapper forget-password-wrapper phoenix-background-svg'>
			<ResetPasswordForm
				isLoading={isLoading}
				isSuccessful={isSuccessfull}
				message={message}
				resetPassword={resetPassword}
			/>
		</div>
	);
};

export default ResetPassword;
