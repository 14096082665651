import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	name: string;
	value: string | number;
}
const Option: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	return (
		<option value={props.value}>
			{t(props.name) as string}
		</option>
	)
}
export default Option;
