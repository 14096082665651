import React, { ReactNode } from 'react';
import { checkPermission, AllPermissionRequired, AnyOnePermissionRequired } from 'shared/util/permission';

/**
 * PermissionManager - render children based on permissions
 * @param permission : number of ALL permissions to check before rendering
 * @param anyOnePermission : number of ANY OF GIVEN permissions to check before rendering
*/

interface Props {
	children: ReactNode;
}

const PermissionManager: React.FC<Props & (AllPermissionRequired | AnyOnePermissionRequired)> = (props) => {
	const { children, ...rest } = props
	const doesHavePermission = checkPermission(rest);
	if (!doesHavePermission) {
		return null;
	}
	return (
		<>{children}</>
	)
}

export default PermissionManager;

