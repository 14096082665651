import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormikValues } from 'formik';

import * as actionTypes from 'store/action-types';
import { State } from 'shared/interface';
import AuthService from 'shared/services/auth.service';
import httpService from 'shared/services/http.service';
import { Translate } from 'shared/components/translate';
import LoginWrapper from 'shared/hoc/auth/loginWrapper';
import { API_CONFIG } from 'shared/constants/constants';
import { CustomForm } from 'shared/components/form/form';
import * as schema from 'shared/constants/validation-schema';
import { FieldConfig } from 'shared/components/form/inputTypes';
import localizationConstants from 'shared/util/translation/constants';
import { createAction, createLoadingSelector } from 'shared/util/utility';
import { ForgetPasswordParams } from '../interface/password.interface';

const ForgetPasswordForm: React.FC = () => {
	const isLoggedIn = AuthService.checkLogin();
	const loading = useSelector((state: State) => loadingSelector(state));
	const navigate = useNavigate();
	const dispatch = useDispatch();
	if (isLoggedIn) {
		navigate('/');
	}

	const forgetPassword = (data: ForgetPasswordParams) => {
		dispatch(createAction(actionTypes.FORGET_PASSWORD_INIT));
		httpService.post(API_CONFIG.path.forgetPassword, data).then(() => {
			dispatch(createAction(actionTypes.FORGET_PASSWORD_SUCCESS));
		}).catch((err: Error) => {
			dispatch(createAction(actionTypes.FORGET_PASSWORD_FAIL));
		});
	}
	const onForgetPassword = (values: FormikValues) => forgetPassword({ email: values.email });

	return (
		<LoginWrapper className='forget-password-wrapper'>
			<CustomForm
				title={localizationConstants.forgetPassword}
				showLabels={true}
				formClassName='m-t'
				schema={schema.forgetPasswordValidationSchema}
				onSubmit={onForgetPassword}
				loading={loading}
				fieldConfig={fieldConfig}
				submitBtnText={localizationConstants.getPasswordResetCode}
				submitBtnClassName='block full-width m-b'
			/>
			<div className='text-center'>
				<Link to='/login'>
					<small><Translate text={localizationConstants.backToLogin} /></small>
				</Link>
			</div>
		</LoginWrapper>
	)
}
const loadingSelector = createLoadingSelector(['FORGET_PASSWORD']);

const fieldConfig: FieldConfig[] = [
	{ type: 'email', label: localizationConstants.email, name: 'email' },
]


export default ForgetPasswordForm;
