import React from 'react';
import localizationConstants from 'shared/util/translation/constants';
import Header from 'shared/components/header/header';
import { firstLevelBreadcrumbs } from 'shared/constants/constants';
import { API_CONFIG } from 'shared/constants/constants';
import PermissionManager from 'shared/hoc/permission/permissionManager';
import LineChartComponent from './lineChartComponent';

/**
 * Dashboard - Render statistics
 */
const Dashboard: React.FC = () => {
	return (
		<>
			<Header
				data={firstLevelBreadcrumbs}
				title={localizationConstants.dashboardTitle} />
			<div className="row">
				<PermissionManager permission={['autoGeneratedLicenseGraphView']}>
					<div className="chart-container col-lg-6 col-md-12 col-sm-12">
						<LineChartComponent
							title={localizationConstants.autoGeneratedLicenseGraphTitle}
							endpoint={API_CONFIG.path.autoGeneratedLicense}
						/>
					</div>
				</PermissionManager>
				<PermissionManager permission={['upcomingCreatedLicenseGraphView']}>
					<div className="chart-container col-lg-6 col-md-12 col-sm-12">
						<LineChartComponent
							title={localizationConstants.upcomingCreatedLicenseGraphTitle}
							endpoint={API_CONFIG.path.upcomingCreatedLicense}
						/>
					</div>
				</PermissionManager>
			</div>
		</>
	)

}

export default Dashboard;
