import React, { useRef, useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, TooltipProps, ResponsiveContainer } from 'recharts';
import { formatDate } from 'shared/util/utility';
import HttpService from 'shared/services/http.service';
import Spinner from 'shared/components/spinner/spinner';
import { Translate } from 'shared/components/translate';
import DateRangePicker from 'shared/components/form/dateRangePicker';
import EmptyDataContainer from 'shared/components/emptyDataContainer/emptyDataContainer';
import { License } from '../interface/dashboard';

interface LineChartProps {
	title: string;
	endpoint: string
}

interface ChartData {
	date: string;
	total: number;
}

const CustomTooltip: React.FC<TooltipProps<number[], string> & { active?: boolean; payload?: any }> = ({
	active,
	payload,
}) => {
	if (active && payload && payload.length) {
		const { date, total } = payload[0].payload as ChartData;
		return (
			<div className="ui-chart-tooltip">
				<div className="ui-chart-tooltip-content">
					<div className="qty">
						<div className="name">Date:&nbsp;</div>
						<div className="value" >{date}</div>
					</div>
					<div className="date">
						<div className="name">Total:&nbsp;</div>
						<div className="value" >{total}</div>
					</div>
				</div>
			</div>
		);
	}
	return null;
};


/**
 * LineChartComponent - line chart componenet
 */
const LineChartComponent: React.FC<LineChartProps> = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [chartData, setChartData] = useState<ChartData[]>([]);
	const [startDate, setStartDate] = useState(moment());
	const [endDate, setEndDate] = useState(moment());

	/**
	 * getData - Fetch chart data
	 * @param range - start_date and end_date
	 */
	const getData = (range: { [key: string]: moment.Moment }) => {
		setStartDate(range.start_date);
		setEndDate(range.end_date);

		const params = {
			start_date: formatDate(range.start_date, 'YYYY-MM-DD'),
			end_date: formatDate(range.end_date, 'YYYY-MM-DD')
		};

		setIsLoading(true);

		HttpService.get(props.endpoint, params)
			.then((response: any) => {
				const chartData = getChartData(response.items);
				setChartData(chartData);
				setIsLoading(false);
			})
			.catch(() => {
				setChartData([]);
				setIsLoading(false);
			});
	};

	/**
	 * getChartData - chart data mapper
	 */
	const getChartData = (items: License[]) => {
		const dateObj: { [key: string]: License[] } = {};
		items.forEach(item => {
			const date = formatDate(item.created_at, 'YYYY-MM-DD');
			if (!dateObj[date]) dateObj[date] = [];
			return dateObj[date].push(item);
		});

		const data = Object.keys(dateObj).map(key => {
			return { date: key, total: dateObj[key].length }
		});

		return data;
	}


	return (
		<div className='custom-card mb-25'>
			<div className='card-heading'>
				<div className='row'>
					<div className='col-xs-12 col-sm-6'>
						<h4><Translate text={props.title} /></h4>
					</div>
					<div className='col-xs-12 col-sm-6'>
						<div className='type-option'>
							<DateRangePicker
								startDate={startDate}
								endDate={endDate}
								handleSubmit={getData}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='card-description-wrapper'>
				{isLoading ? <Spinner /> : isEmpty(chartData) ? <EmptyDataContainer /> : (
					<div className='row'>
						<ResponsiveContainer width="98%" height={400}>
							<LineChart
								data={chartData}
								margin={{
									top: 50, right: 30, left: -10, bottom: 0,
								}}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="date" />
								<YAxis />
								<Tooltip content={<CustomTooltip />} />
								<Line type="monotone" dataKey="total" activeDot={{ r: 8 }} />
							</LineChart>
						</ResponsiveContainer>
					</div>
				)}
			</div>
		</div>
	)
}

export default LineChartComponent;
