import React, { ReactNode } from 'react';
import logo from 'assets/images/logo.png';
import { refreshLanguage } from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import Option from 'shared/components/form/option';

interface Props {
	className?: string;
	page?: string;
	children?: ReactNode;
}
const LoginWrapper: React.FC<Props> = (props) => {
	const [lang, setLang] = React.useState(localStorage.getItem('lang') || 'de');
	return (
		<div className={`gray-bg login-wrapper ${props.className || ''}`}>
			<div className='middle-box loginscreen animated fadeInDown'>
				<div>
					<div className='language-dropdown'>
						<select
							data-testid='language-dropdown'
							value={lang}
							className='form-control'
							onChange={(e) => {
								const language = e.target.value;
								refreshLanguage(language);
								setLang(language);
								// refresh to change language
								window.location.reload();
							}}
						>
							<Option name={localizationConstants.german} value='de' />
							<Option name={localizationConstants.english} value='en' />
						</select>
					</div>
					<div className='text-center' data-testid='logo'>
						<img src={logo} alt='logo' className='logo-name' />
					</div>
					<h1 className='logo-name'>{!!props.page ? 'Phoenix' : 'PosBill'}</h1>
					{props.children}
				</div>
			</div>

		</div>
	)
}

export default LoginWrapper;
