import React, { ChangeEventHandler, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Field, ErrorMessage, FieldProps, FormikValues } from 'formik';
import Collapsible from 'react-collapsible';
import FieldErrorMessage from './error';
import { Translate } from '../translate';
import Option from './option';
import { useTranslation } from 'react-i18next';
import localizationConstants from 'shared/util/translation/constants';
import { reactSelectStyles } from 'shared/constants/constants';
import i18next from 'i18next';
export interface DropDownAndCheckboxOptions {
	id?: string | number;
	name: string;
	value: string | number;
	translationKey?: string;
}

export type TInputType = 'text' | 'textarea' | 'email' | 'password' | 'number' | 'dropdown' | 'checkbox' | 'dropdown' | 'file';
export interface FieldConfig {
	type: TInputType;
	label: string;
	name: string;
	mode?: string;
	isCollapsible?: boolean;
	className?: string;
	placeHolder?: string;
	hideDefaultOption?: boolean;
	otherOptions?: {
		dropDownOptions?: DropDownAndCheckboxOptions[];
		checkboxOptions?: DropDownAndCheckboxOptions[];
		trimWhiteSpaceAroundCheckBox?: boolean;
		isMultiSelect?: boolean;
		note?: string;
		suggestion?: string;
		sampleFile?: {
			label?: JSX.Element;
			link: string;
		};
		min?: number
		max?: number
	};
}
interface TextFieldProps {
	name: string;
	mode?: string;
	placeholder: string;
	config: FieldConfig;
	setFieldValue: (field: string, value: any) => void;

	disabled?: boolean;
	disableWheel?: boolean;
	readOnly?: boolean;
	type?: 'text' | 'textarea' | 'email' | 'password' | 'number' | 'checkbox' | 'dropdown' | 'file';
	className?: string;
	autoComplete?: string;
	showLabels?: boolean;
	isMultiFile?: boolean;
	supportedType?: string;
	onSelectFile?: (event: any) => void;
}

const RenderInput: React.FC<TextFieldProps & { field: any }> = props => {
	const { t } = useTranslation();
	const suggestion = (props.config.otherOptions || {}).suggestion
	switch (props.type) {
		// render text input in case of text, textarea, email, password and number
		case 'textarea':
			return (
				<>
					{props.showLabels && props.config.label &&
						<label className='ignore-text-capitalize input-label-wrapper'>
							<Translate text={props.config.label} />
						</label>}
					<textarea
						{...props.field}
						value={getValue(props.field.value)}
						id={props.name}
						onChange={(e) => props.setFieldValue(e.target.name, e.target.value)}
						className={`${props.className || ''} form-control`}
						placeholder={t(props.placeholder)}
						disabled={props.disabled ? true : false}
						readOnly={props.readOnly}
						autoComplete={`${props.autoComplete || 'off'}`}
					/>
					<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
					{getNote((props.config.otherOptions || {}).note)}
				</>
			);
		case 'text':
		case 'email':
		case 'password':
			return (
				<>
					{props.showLabels && props.config.label &&
						<label className='ignore-text-capitalize input-label-wrapper'>
							<span><Translate text={props.config.label} /></span><br />{suggestion && <span className="field-suggestion">( <Translate text="suggested" /> <Translate text={props.config.label} />: {suggestion})</span>}
						</label>}
					<input
						{...props.field}
						value={getValue(props.field.value)}
						id={props.name}
						type={props.type}
						onWheelCapture={(e) => props.disableWheel && e.currentTarget.blur()}
						className={`${props.className || ''} form-control`}
						placeholder={t(props.placeholder)}
						disabled={props.disabled ? true : false}
						readOnly={props.readOnly}
						autoComplete={`${props.autoComplete || 'off'}`}
					/>
					<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
					{getNote((props.config.otherOptions || {}).note)}
				</>
			);
		case 'number':
			return (
				<>
					{props.showLabels && props.config.label &&
						<label className='ignore-text-capitalize input-label-wrapper'>
							<span><Translate text={props.config.label} /></span><br />
							{suggestion && <span className="field-suggestion">( <Translate text="suggested" /> <Translate text={props.config.label} />: {suggestion})</span>}
						</label>}
					<input
						{...props.field}
						value={getValue(props.field.value)}
						id={props.name}
						onChange={(e) => {
							const inputValue = e.target.value;
							if (inputValue === '' || isNaN(parseFloat(inputValue))) {
								props.setFieldValue(e.target.name, '');
							} else {
								props.setFieldValue(e.target.name, parseFloat(inputValue)); // Handles both integers and floats
							}
						}}
						type={props.type}
						onWheelCapture={(e) => props.disableWheel && e.currentTarget.blur()}
						className={`${props.className || ''} form-control`}
						placeholder={t(props.placeholder)}
						disabled={props.disabled ? true : false}
						readOnly={props.readOnly}
						autoComplete={`${props.autoComplete || 'off'}`}
						min={props.config.otherOptions?.min}
						max={props.config.otherOptions?.max}
						step="any" // Allows float inputs
					/>
					<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
					{getNote((props.config.otherOptions || {}).note)}
				</>
			);
		case 'checkbox':
			return (
				// <div className='form-group d-flex align-items-baseline'>
				<div className='form-group align-items-baseline'>
					{props.config.label &&
						<>{
							props.config.isCollapsible
								? (
									<Collapsible
										easing="ease-in-out"
										transitionTime={250}
										trigger={getCollapsiblePanelHeader(props.config.label, false, props)}
										triggerWhenOpen={getCollapsiblePanelHeader(props.config.label, true, props)}>
										{getCheckBoxFields(props)}
									</Collapsible>
								) : (
									<label className='ignore-text-capitalize col-xs-4 col-sm-2 control-label'>
										<Translate text={props.config.label} />
									</label>
								)
						}</>
					}
					{!props.config.isCollapsible && getCheckBoxFields(props)}
				</div>
			);

		// render dorpdown when dropdown type is provided
		case 'dropdown':
			const setDropdownValue: ChangeEventHandler<any> = evt => {
				if ((props.config.otherOptions || {}).isMultiSelect) {
					props.setFieldValue(props.config.name, [].slice.call(evt.target.selectedOptions).map((option: any) => option.value));
				} else {
					props.setFieldValue(props.config.name, evt.target.value);
				}
			};
			return (
				<div className='row'>
					{props.config.label &&
						<label className='ignore-text-capitalize col-sm-12 control-label'>
							<Translate text={props.config.label} />
						</label>}
					<div className='col-sm-12'>
						<select
							disabled={props.disabled ? true : false}
							placeholder={props.config.placeHolder || props.config.label}
							value={props.field.value}
							onChange={setDropdownValue}
							name={props.config.name}
							className='form-control'
							multiple={(props.config.otherOptions || {}).isMultiSelect}
						>
							{!props.config.hideDefaultOption && <option value=''>---SELECT---</option>}
							{geDropDownOptions(props.config).map(option => (
								<Option key={option.id || option.name} name={option.name} value={option.value} />
							))}
						</select>
						<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
						{getNote((props.config.otherOptions || {}).note)}
					</div>
				</div>
			);
		case 'file':
			delete props.field.value;
			return (
				<>
					{props.showLabels && props.config.label &&
						<>
							<label className='ignore-text-capitalize input-label-wrapper'>
								<Translate text={props.config.label} /> {(props.config.otherOptions || {}).sampleFile &&
									<span>(&nbsp;
										<a href={((props.config.otherOptions || {}).sampleFile || {}).link} download>
											{((props.config.otherOptions || {}).sampleFile || {}).label
												? ((props.config.otherOptions || {}).sampleFile || {}).label
												: <Translate text={localizationConstants.downloadSampleFile} />
											}
										</a>
										&nbsp;)</span>
								}
							</label>
							<br />
						</>
					}
					<input
						{...props.field}
						id={props.name}
						name={props.name}
						type={props.type}
						value={""}
						className={`${props.className || ''} form-control hide`}
						multiple={props.isMultiFile || false}
						onChange={(e) => {
							props.onSelectFile ? props.onSelectFile(e) : props.setFieldValue(e.target.name, e.target.files)
						}}
					/>
					<label htmlFor={props.name} className='btn btn-info'>
						<Translate text={props.config.label || localizationConstants.upload} />
					</label>
					<p className="mb-0"><Translate text={localizationConstants.supportedType} />: <b>{props.supportedType}</b></p>
					<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
					{getNote((props.config.otherOptions || {}).note)}
				</>
			);
		default:
			return <></>;
	}
};

/**
 * common input field component
 * renders input based on the field configuration
 * @param props { field, form: { touched, errors }, ...props }
 */
const Input: React.FC<TextFieldProps> = props => {
	const fieldRender = ({ field }: { field: any }) => {
		return <RenderInput {...props} field={field} />;
	};

	return (
		<Field
			name={props.name}
			render={fieldRender}
		/>
	);
};

const RenderAsyncSelect = (props: any) => {
	const { t } = useTranslation();
	return (
		<>
			{props.config.label &&
				<label className='ignore-text-capitalize input-label-wrapper'>
					<Translate text={props.config.label} />
				</label>}
			<AsyncSelect
				{...props.field}
				placeholder={t(props.placeholder) || ''}
				value={props.value}
				maxMenuHeight="200px"
				onChange={props.onChange}
				onInputChange={props.onInputChange}
				defaultOptions={props.defaultOptions}
				noOptionsMessage={props.noOptionsMessage}
				loadOptions={props.loadOptions}
				options={props.options}
				getOptionLabel={props.getOptionLabel}
				getOptionValue={props.getOptionValue}
				isClearable={props.isClearable ? true : false}
				isDisabled={props.disabled ? true : false}
				styles={reactSelectStyles}
				closeMenuOnSelect={props.closeMenuOnSelect ? true : false}
				isSearchable={props.isSearchable ? true : false}
			/>
		</>
	);
};

const Asyncselect: React.FC<FieldProps<FormikValues> & any> = props => {
	const fieldRender = ({ field }: { field: any }) => {
		return <RenderAsyncSelect {...props} field={field} />;
	};
	return (
		<Field
			name={props.name}
			render={fieldRender}
		/>
	);
};

/**
 * getCheckboxValue - returns check box value, after changing value with change event of html input element
 * @param field - field returned by formik
 * @param evt - html input change event, linked with checkbox input
 */
const getCheckboxValue = (field: any, evt: React.ChangeEvent<HTMLInputElement>) => {
	// if field value is empty, or null initially, assign it as empty array of strings
	if (!field.value) {
		field.value = [];
	}
	const index = field.value.indexOf(evt.target.value.toString());
	// if event gives `checked` = true, push target value to field value
	if (evt.target.checked) {
		field.value.push(evt.target.value.toString());
	} else if (index !== -1) {
		// else remove target value from field value
		field.value.splice(index, 1);
	}
	// return value
	return field.value;
};

const geDropDownOptions = (config: FieldConfig) => {
	return ((config.otherOptions || {}).dropDownOptions || []);
};

const geCheckboxOptions = (config: FieldConfig) => {
	return ((config.otherOptions || {}).checkboxOptions || []);
};


const getValue = (value?: string | number) => {
	if (value === undefined || value === null) {
		return '';
	}
	return value;
};

const getNote = (note: string | undefined | null) => {
	if (note === undefined || note === null) {
		return '';
	}
	return <div className="field-note"><Translate text="note" /> : <Translate text={note} /></div>;
};

const getCheckBoxFields = (props: TextFieldProps & { field: any }) => {
	const trimWhiteSpaceAroundCheckBox = (props.config.otherOptions || {}).trimWhiteSpaceAroundCheckBox;
	return (
		<>
			<div className='checkbox-wrapper col-xs-12 col-sm-12 col-md-12 col-lg-12'>
				{geCheckboxOptions(props.config).map(option => {
					const setCheckboxvalue: ChangeEventHandler<HTMLInputElement> = evt => {
						props.setFieldValue(props.config.name, getCheckboxValue(props.field, evt));
					};
					const isChecked = (props.field.value || []).map((key: any) => (key || '').toString()).includes(option.value.toString());
					return (
						<div className={`checkbox-content ${trimWhiteSpaceAroundCheckBox ? '' : 'col-lg-3 col-md-4 col-sm-6 col-xs-12'}`} key={option.value}>
							<label
								id={option.translationKey ? option.translationKey.replace(".", "") : 'nn'}
								className='ignore-text-capitalize checkbox-label'
								data-tkey={option.translationKey}>
								<p><Translate text={option.name} /></p>
								<input
									placeholder={option.name}
									checked={isChecked}
									onChange={setCheckboxvalue}
									type='checkbox'
									name={option.name}
									value={option.value} />
								{//@ts-ignore
									<span className='checkmark' mode={props.mode || ''} />
								}
							</label>
						</div>
					);
				})}
			</div>
			<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
			{getNote((props.config.otherOptions || {}).note)}
		</>
	)
}

const getCollapsiblePanelHeader = (title: string, isOpen: boolean, props: TextFieldProps & { field: any }) => {
	const isChecked = props.field.value.length === geCheckboxOptions(props.config).length;
	const partialCheckedCls = !isChecked && props.field.value.length > 0 ? 'partial-checked' : '';
	const setCheckboxvalue = (event: any) => {
		if (isChecked || isOpen) {
			event.preventDefault();
			event.stopPropagation();
		}
		if (!isChecked) {
			const checkedValue = geCheckboxOptions(props.config).map((checkBox: any) => checkBox.value.toString());
			props.setFieldValue(props.config.name, checkedValue);
		} else {
			props.setFieldValue(props.config.name, []);
		}
	};
	return (
		<div id={props.config.name} className='collapsible-custom-header checkbox-wrapper' >
			<div className="checkbox-content" onClick={(e) => setCheckboxvalue(e)}>
				<label className='ignore-text-capitalize col-lg-10 col-md-10 col-xs-10 col-sm-10 control-label checkbox-label' >
					<p><Translate text={title} /></p>
					<input
						checked={isChecked}
						onChange={() => 0}
						type='checkbox'
						name={i18next.t(title)}
					/>
					<span className={`checkmark ${partialCheckedCls}`} />
				</label>
			</div>
			<button ><i className={`fa fa-chevron-${isOpen ? 'up' : 'down'}`} /></button>
		</div >
	);
}

export {
	Input,
	Asyncselect
};
