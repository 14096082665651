import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosResponse, AxiosError } from 'axios';
import * as actionTypes from 'store/action-types';
import { ResponseObj } from 'shared/interface';
import { createAction } from 'shared/util/utility';
import { axiosInstance } from 'shared/services/http.service';
import NotificationComponent from 'shared/components/notification/notification';

interface Props {
	children?: ReactNode;
}
const WithErrorHandler: React.FC<Props> = (props) => {
	const [message, setMessage] = useState('');
	const [type, setType] = useState('')
	const dispatch = useDispatch();

	let resInterceptor: number;

	/**
	 * addNotification - add notification msg, and clear after added toastr
	 */
	const addNotification = (message: string, type: string) => {
		setMessage(message);
		setType(type);
		setTimeout(() => {
			setMessage('');
			setType('');
		}, 500);
	}
	const logout = () => dispatch(createAction(actionTypes.AUTH_LOGOUT_SUCCESS));
	/**
		 * add response interceptor before component gets mounted
		 * check if response data contains isError = true, if yes, show an error message
		 * if response gives a non-200 error code, show error from error data
		 * if response contains a message to show, show success notification
		 */
	useEffect(() => {
		resInterceptor = axiosInstance.interceptors.response.use((res: AxiosResponse<ResponseObj<any>>) => {
			if (res.data && res.data.code !== 200 && res.data.code !== 201 && res.data.message) {
				addNotification(res.data.message, 'error');
				throw new Error(res.data.message);
			} else if (res.data) {
				if (res.data.message) {
					addNotification(res.data.message, 'success');
				}
			}
			return res;
		}, (error: AxiosError) => {
			let show = true;
			if (error && error.request && error.request.responseURL.includes('customer-phoenix-license/') && error.request.responseURL.includes('/companies/') && error.request.status === 404) {
				show = false;
			}
			if (error && error.request && error.request.responseURL.includes('/company/confirm-validation') && error.request.status === 504) {
				throw new Error('504 Timeout');
			}
			// check if error is having data
			if (error.response && error.response.data && error.response.status && show) {
				const status = error.response.status;
				const responseData: any = error.response.data;
				// is http error code is 401, and message contains **SESSION_EXPIRED**, log out of the application
				if (status === 401 && responseData.data && responseData.data.type === 'SESSION_EXPIRED') {
					logout();
				} else if (responseData.errorMessages && Object.keys(responseData.errorMessages).length) {
					// if error response contains any validation message, fetch it from response, and add error notification
					const validationError = responseData.errorMessages[Object.keys(responseData.errorMessages)[0]];
					addNotification(validationError[0], 'error');
				} else if (error.response && responseData && responseData.message) {
					// if error data contains message field, add error notification
					addNotification(responseData.message, 'error');
				}
				throw error;
			}
		});
		/**
	 * eject response interceptor on when component is about to unmount
	 */
		return () => {
			axiosInstance.interceptors.response.eject(resInterceptor);
		};
	}, [logout]);

	return (
		<>
			<NotificationComponent
				open={message ? true : false}
				type={type}
				message={message}
			/>
			{props.children}
		</>
	)
}

export default WithErrorHandler;
