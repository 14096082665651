import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import isEmpty from 'lodash/isEmpty';

import PhoenixLoginWrapper from 'shared/hoc/auth/phoenixLoginWrapper';
import { API_CONFIG } from 'shared/constants/constants';
import HttpService from 'shared/services/http.service';
import Spinner, { Loader } from 'shared/components/spinner/spinner';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/components/translate';
import * as schema from 'shared/constants/validation-schema';
import { Input, FieldConfig } from 'shared/components/form/inputTypes';
import Button from 'shared/components/form/button';
import WithTranslateFormErrors from 'shared/hoc/withTranslationError';
import PasswordAssistent from 'shared/components/passwordAssistent/passwordAssistent';
import PhoenixLogo from 'assets/images/phoenix_logo_green.png';

/**
 * Validate - company verification
 */
const Validate: React.FC = () => {
	const [searchParams] = useSearchParams();
	const { token = "" } = useParams();
	const [message, setMessage] = useState('');
	const [companyMessage, setCompanyMessage] = useState('');
	const [selfLogin, setSelfLogin] = useState('');
	const [isActionLoading, setIsActionLoading] = useState(false);
	const [isLoadingEmail, setIsLoadingEmail] = useState(false);
	const [isLoadingCompanyCreation, setIsLoadingCompanyCreation] = useState(false);
	const [isEmailVerified, setIsEmailVerified] = useState(false);
	const [isCompanyCreated, setIsCompanyCreated] = useState(false);
	const [showPasswordInput, setShowPasswordInput] = useState(false);

	/**
	 * confirmValidation - To show password input or not
	 */
	const confirmValidation = () => {
		const email = searchParams.get('email');
		// const parsed = queryString.parse(this.props.location.search);
		const params: any = {
			token,
			email
		};
		setIsActionLoading(true);
		HttpService.post(API_CONFIG.path.confirmValidationAction, params).then((response: any) => {
			setIsActionLoading(false);
			if (response.isShowPasswordInput) {
				setShowPasswordInput(true);
			} else if (response.isCompanyCreatedInPhoenix && response.isEmailVerified) {
				setIsEmailVerified(true);
				setIsCompanyCreated(true);
				setCompanyMessage(localizationConstants.companyAlreadyValidatedMessage);
			} else {
				validateCompany();
			}
		}).catch((err: Error) => {
			setIsActionLoading(false);
		});
	}

	/**
	 * validateCompany - check company email verification
	 */
	const validateCompany = (values: FormikValues = {}) => {
		// const parsed = queryString.parse(this.props.location.search);
		const email = searchParams.get('email');
		const params: any = {
			token,
			email
		};
		if (!isEmpty(values) && values.password) {
			params.password = values.password.trim();
		}
		setIsLoadingEmail(true);
		setShowPasswordInput(false);
		HttpService.post(API_CONFIG.path.validateCompanyEmail, params).then((response: any) => {
			setIsLoadingEmail(false);
			setIsEmailVerified(true);
			if (response.status === 'successful') {
				setIsCompanyCreated(true);
				setCompanyMessage(response.alreadyValidated ? localizationConstants.companyAlreadyValidatedMessage : localizationConstants.companyVerificationSuccessMessage);
			} else if (!!response.company_id) {
				setIsLoadingCompanyCreation(true);
				const companyStatus = setInterval(() => {
					HttpService.get(`${API_CONFIG.path.companyCreation}/${response.company_id}`).then((res: any) => {
						if (res.status === 'successful') {
							clearInterval(companyStatus);
							setIsLoadingCompanyCreation(false);
							setIsCompanyCreated(true);
							setSelfLogin(res.selfLogin);
							setCompanyMessage(res.alreadyValidated ? localizationConstants.companyAlreadyValidatedMessage : localizationConstants.companyVerificationSuccessMessage);
						}
					}).catch((err: Error) => {
						setIsLoadingCompanyCreation(false);
						setIsCompanyCreated(false);
					});
				}, 3000);
			}
		}).catch((err: Error) => {
			if (err.message === '504 Timeout') {
				setIsLoadingEmail(false);
				setIsEmailVerified(true);
				setMessage(localizationConstants.companyVerificationTimeoutMessage);
			} else {
				setIsLoadingEmail(false);
				setIsEmailVerified(false);
				setMessage(localizationConstants.companyVerificationFailMessage);
			}
		});
	}
	useEffect(() => {
		confirmValidation();
	}, []);

	return (
		<PhoenixLoginWrapper className='validate-card-wrapper phoenix-validate-card-wrapper'>
			<div className='card'>
				<div className='content'>
					<img
						src={PhoenixLogo}
						style={{ width: '200px' }}
						alt="phoenix-logo"
						className="m-b-sm"
					/>
					{isActionLoading && <Spinner />}
					{!isActionLoading && showPasswordInput && (
						<div className='custom-form'>
							<h2 className="font-bold mt-3">
								<Translate text={localizationConstants.phoenixPassword} />
							</h2>
							<div className='row'>
								<div className='col-lg-12'>
									<div className='ibox float-e-margins'>
										<div className="ibox-content validation-action-form-wrapper mt-3">
											<Formik
												initialValues={{ password: '' }}
												validateOnBlur={true}
												validateOnChange={true}
												onSubmit={validateCompany}
												validationSchema={schema.phoenixPasswordValidationSchema}
											>
												{({ handleSubmit, setFieldValue, setFieldTouched, errors, touched, values }) => (
													<WithTranslateFormErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
														<form onSubmit={handleSubmit}>
															<fieldset>
																{fieldConfig.map((config, index) => (
																	<div key={index} className="form-group">
																		<Input
																			showLabels={false}
																			setFieldValue={setFieldValue}
																			config={config}
																			placeholder={config.placeHolder || config.label}
																			type={config.type}
																			name={config.name}
																		/>
																		{config.name === 'password' &&
																			<PasswordAssistent value={values.password} />
																		}
																	</div>
																))}
																<div className='mt-30 d-flex align-items-center justify-content-center'>
																	{/*{cancelSubmit &&
																			<Button
																				btnType='danger'
																				onClick={cancelSubmit}
																				type='button'
																				className='mr-2 width-100px'>{localizationConstants.cancel}</Button>
																		}*/}
																	<Button
																		className="block full-width m-b width-100px"
																		loading={false}
																		type='submit'
																		btnType='primary'>{localizationConstants.nextButton}</Button>
																</div>

															</fieldset>
														</form>
													</WithTranslateFormErrors>
												)}
											</Formik>

										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{!isActionLoading && !showPasswordInput && (
						<>
							{(!isEmailVerified && !isLoadingEmail) && <div className='label-text light'><i className='fa fa-circle-o-notch' />&nbsp; <Translate text={localizationConstants.emailVerification} /></div>}
							{isLoadingEmail && <div className='label-text'><Loader />&nbsp; <Translate text={localizationConstants.verifyingEmail} /></div>}
							{isEmailVerified && <div className='label-text success'><i className='fa fa-check' />&nbsp; <Translate text={localizationConstants.successfulEmailVerified} /></div>}

							{!isCompanyCreated && !isLoadingCompanyCreation && <div className='label-text light'><i className='fa fa-circle-o-notch' />&nbsp; <Translate text={localizationConstants.companyCreation} /></div>}
							{isLoadingCompanyCreation && <div className='label-text'><Loader />&nbsp; <Translate text={localizationConstants.creatingCompany} /></div>}
							{isCompanyCreated && <div className='label-text success'><i className='fa fa-check' />&nbsp; <Translate text={companyMessage} /></div>}
						</>
					)}
				</div>
				{isEmailVerified && isCompanyCreated &&
					<div className='content btn-wrapper no-margins mb-3'>
						<a className='btn btn-primary ignore-text-capitalize mt-2' href={!isEmpty(selfLogin) ? selfLogin : process.env.REACT_APP_PHOENIX_URL}>
							<Translate text={localizationConstants.startPhoenixNow} />
						</a>
					</div>
				}
				{!isLoadingEmail && <Translate text={message} />}
			</div>
		</PhoenixLoginWrapper>
	);
}

export default Validate;

const fieldConfig: FieldConfig[] = [
	{ type: 'password', label: localizationConstants.phoenixPassword, name: 'password', placeHolder: localizationConstants.password },
	{ type: 'password', label: localizationConstants.confirmPassword, name: 'password_confirmation' }
];
