import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { API_CONFIG } from 'shared/constants/constants';
import HttpService from 'shared/services/http.service';
import { Loader } from 'shared/components/spinner/spinner';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/components/translate';
import AuthService from 'shared/services/auth.service';
import { refreshLanguage } from 'shared/util/localization';
import logo from 'assets/images/logo.png';

/**
 * PhoenixOnboarding - phoenix company onboarding
 */
const PhoenixOnboarding: React.FC = () => {
	const { token = "" } = useParams();
	const [searchParams] = useSearchParams();
	const [message, setMessage] = useState('');
	const [companyMessage, setCompanyMessage] = useState('');
	const [selfLogin, setSelfLogin] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingCompanyCreation, setIsLoadingCompanyCreation] = useState(false);
	const [isCompanyCreated, setIsCompanyCreated] = useState(false);

	/**
	 * createCompany - phoenix company onboarding
	 */
	const createCompany = () => {
		const branchIdParam = searchParams.get('branchId');
		const email = searchParams.get('email');
		const name = searchParams.get('name');
		const password = searchParams.get('password');
		const billingPeriod = searchParams.get('billingPeriod');
		const phone = searchParams.get('phone');
		const expiring_date = searchParams.get('expiring_date');
		const accept_agb = searchParams.get('accept_agb');
		const accept_swn = searchParams.get('accept_swn');
		const partnerId = searchParams.get('partnerId');
		const language = searchParams.get('lang');

		const branchId = !!branchIdParam ? branchIdParam : '8';
		const params: any = {
			branchId,
			email
		};
		if (!!name) {
			params.name = name;
		}
		if (!!password) {
			params.password = password;
		}
		if (!!billingPeriod) {
			params.billingPeriod = billingPeriod;
		}
		if (!!phone) {
			params.phone = phone;
		}
		if (!!expiring_date) {
			params.expiring_date = expiring_date;
		}
		if (!!accept_agb) {
			params.accept_agb = accept_agb === 'true' ? true : false;
		}
		if (!!accept_swn) {
			params.accept_swn = accept_swn === 'true' ? true : false;
		}
		if (!!partnerId) {
			params.partnerId = partnerId;
		}
		if (!!language) {
			const lang = language as string;
			params.lang = lang;
			AuthService.setUserLanguage(lang);
			refreshLanguage(lang);
		}
		setIsLoading(true);
		HttpService.post(API_CONFIG.path.phoenixCompanyOnboarding, params).then((response: any) => {
			setIsLoading(false);
			if (response.status === 'successful') {
				setIsCompanyCreated(true);
				setCompanyMessage(localizationConstants.companyVerificationSuccessMessage);
			} else if (!!response.company_id) {
				setIsLoadingCompanyCreation(true);
				const companyStatus = setInterval(() => {
					HttpService.get(`${API_CONFIG.path.companyCreation}/${response.company_id}`).then((res: any) => {
						if (res.status === 'successful') {
							clearInterval(companyStatus);
							setIsLoadingCompanyCreation(false);
							setIsCompanyCreated(true);
							setSelfLogin(res.selfLogin);
							setCompanyMessage(localizationConstants.companyVerificationSuccessMessage);
						}
					}).catch((err: Error) => {
						setIsLoadingCompanyCreation(false);
						setIsCompanyCreated(false);
					});
				}, 3000);
			}
		}).catch((err: Error) => {
			if (err.message === '504 Timeout') {
				setIsLoading(false);
				setMessage(localizationConstants.companyVerificationTimeoutMessage);
			} else {
				setIsLoading(false);
				setMessage(localizationConstants.companyVerificationFailMessage);
			}
		});
	}

	useEffect(() => {
		createCompany();
	}, []);

	return (
		<div className={`gray-bg login-wrapper validate-card-wrapper`}>
			<div className='middle-box loginscreen animated fadeInDown'>
				<div>
					<div className='text-center' data-testid='logo'>
						<img src={logo} alt='logo' className='logo-name' />
					</div>
					<h1 className='logo-name'>PosBill</h1>
					<div className='card'>
						<div className='content'>
							{isLoading && <Loader />}
							{!isLoading &&
								<>
									{isLoadingCompanyCreation &&
										<div className='label-text d-flex d-flex-dir-column justify-content-center align-items-center'>
											<Loader />
											<span className='mt-2' />
											<Translate text={localizationConstants.creatingCompany} />
										</div>
									}
									{isCompanyCreated && <div className='label-text success'><i className='fa fa-check' />&nbsp; <Translate text={companyMessage} /></div>}
								</>
							}
						</div>
						{isCompanyCreated &&
							<div className='content btn-wrapper no-margins mb-3'>
								<a className='btn btn-primary ignore-text-capitalize mt-2' href={!isEmpty(selfLogin) ? selfLogin : process.env.REACT_APP_PHOENIX_URL}>
									<Translate text={localizationConstants.startPhoenixNow} />
								</a>
							</div>
						}
						{!isLoading && <Translate text={message} />}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PhoenixOnboarding;
