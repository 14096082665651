import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors, FormikValues, FormikTouched } from 'formik';

const useTranslateFormErrors = (errors: FormikErrors<FormikValues>, touched: FormikTouched<FormikValues>, setFieldTouched: (field: string, isTouched?: boolean) => void) => {
	const { i18n } = useTranslation();
	useEffect(() => {
		i18n.on('languageChanged', lng => {
			// Object.keys(errors).forEach(fieldName => {
			// if (Object.keys(touched).includes(fieldName)) {
			// setFieldTouched(fieldName);
			// }
			// });
		});
		return () => {
			i18n.off('languageChanged', lng => { });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors]);
};

interface Props {
	errors: FormikErrors<FormikValues>;
	touched: FormikTouched<FormikValues>;
	setFieldTouched: (field: string, isTouched?: boolean) => void;
	children: ReactNode
}

const WithTranslateFormErrors: React.FC<Props> = (props) => {
	const { errors, touched, setFieldTouched } = props;
	useTranslateFormErrors(errors, touched, setFieldTouched);
	return <>{props.children}</>;
};

export default WithTranslateFormErrors;
