import React from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FormikValues } from "formik";

import * as actionTypes from "store/action-types";
import { State } from "shared/interface";
import AuthService from "shared/services/auth.service";
import httpService from "shared/services/http.service";
import LoginWrapper from "shared/hoc/auth/loginWrapper";
import { Translate } from "shared/components/translate";
import { API_CONFIG } from "shared/constants/constants";
import { CustomForm } from "shared/components/form/form";
import * as schema from "shared/constants/validation-schema";
import { FieldConfig } from "shared/components/form/inputTypes";
import localizationConstants from "shared/util/translation/constants";
import { createAction, createLoadingSelector } from "shared/util/utility";
import { ResetPasswordParams } from "../interface/password.interface";

const ResetPasswordForm: React.FC = () => {
	const isLoggedIn = AuthService.checkLogin();
	const dispatch = useDispatch();
	const loading = useSelector((state: State) => loadingSelector(state));
	const auth = useSelector((state: State) => state.auth);
	let { token } = useParams();
	token = token || "";
	const navigate = useNavigate();
	if (isLoggedIn || auth.closeModal) {
		navigate("/login");
	}
	const onResetPassword = (values: FormikValues) =>
		resetPassword(values as ResetPasswordParams, token || "");
	const resetPassword = (data: ResetPasswordParams, token: string) => {
		dispatch(createAction(actionTypes.RESET_PASSWORD_INIT));
		data.token = token;
		httpService
			.post(`${API_CONFIG.path.resetPassword}/${token}`, data)
			.then(() => {
				dispatch(createAction(actionTypes.RESET_PASSWORD_SUCCESS));
				dispatch(createAction("RESET_MODAL"));
			})
			.catch((err: Error) => {
				dispatch(createAction(actionTypes.RESET_PASSWORD_FAIL));
			});
	};
	return (
		<LoginWrapper>
			<CustomForm
				title={localizationConstants.resetYourPassword}
				showLabels={true}
				formClassName="m-t"
				schema={schema.resetPasswordValidationSchema}
				onSubmit={onResetPassword}
				loading={loading}
				fieldConfig={fieldConfig}
				submitBtnText={localizationConstants.resetPassword}
				submitBtnClassName="block full-width m-b"
			/>
			<div className="text-center">
				<Link to="/login">
					<small>
						<Translate text={localizationConstants.backToLogin} />
					</small>
				</Link>
			</div>
		</LoginWrapper>
	);
};
const loadingSelector = createLoadingSelector(["RESET_PASSWORD"]);
const fieldConfig: FieldConfig[] = [
	// { type: 'email', label: 'Email', name: 'email' },
	{
		type: "password",
		label: localizationConstants.newPassword,
		name: "password",
	},
	{
		type: "password",
		label: localizationConstants.confirmPassword,
		name: "password_confirmation",
	},
];

export default ResetPasswordForm;
