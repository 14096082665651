import React from "react";
import { Link, useLocation } from "react-router-dom";
import { SideBarMenu } from "features/login/interface/login.interface";
import Collapse from "react-collapse";
import { Translate } from "shared/components/translate";
import localizationConstants from "shared/util/translation/constants";
interface OwnProps {
	renderSideNav: () => void;
	menu: SideBarMenu;
	isOpen: boolean;
	toggleIsOpen: (name: string) => void;
	hideSidebar: () => void;
}

/**
 * getPathElements - split any given navigation path at every `/` and return the array
 */
const getPathElements = (path: string) => {
	return path.split("/").filter((str) => str !== "");
};

const getActiveClass = (
	currentPathStr: string,
	actualPathStr: string
): string => {
	// for base path, it's compulsary to have both path as `/`, in order to show `active` status
	if (actualPathStr === "/") {
		if (currentPathStr === actualPathStr) {
			return "active";
		}
		return "";
	}
	const actualPath = getPathElements(actualPathStr);
	const currentPath = getPathElements(currentPathStr);
	// if the first level path matches for actual path, and current path, return `active`
	if (actualPath[0] === currentPath[0]) {
		return "active";
	}
	return "";
};

const getSubActiveClass = (
	currentPathStr: string,
	actualPathStr: string
): string => {
	const actualPath = getPathElements(actualPathStr);
	const currentPath = getPathElements(currentPathStr);
	if (
		(currentPathStr.includes("/organizations/list") ||
			currentPathStr.includes("/organizations/payment-status")) &&
		actualPathStr.includes("/organizations/license")
	) {
		return "active";
	}
	// for sub menu to be active, first and second level path must match
	if (actualPath[0] === currentPath[0] && actualPath[1] === currentPath[1]) {
		return "active";
	}
	return "";
};

const icons: { [key: string]: string } = {
	[localizationConstants.users]: "user",
	[localizationConstants.dashboardTitle]: "dashboard",
	// 'emailTemplate.index': 'envelope',
	[localizationConstants.posbillAdmin]: "id-badge",
	[localizationConstants.permissions]: "key",
	[localizationConstants.role]: "id-card",
	[localizationConstants.organizationsTitle]: "sitemap",
	[localizationConstants.birdhubAdmin]: "user",
	[localizationConstants.phoenixAdmin]: "user",
	[localizationConstants.jumpbirdAdmin]: "user",
	[localizationConstants.resigoAdmin]: "user",
	[localizationConstants.tse]: "desktop",
	[localizationConstants.accounting]: "file-text",
	// [localizationConstants.invoices]: 'file-text',
	[localizationConstants.tools]: "cogs",
	[localizationConstants.offerTool]: "cogs",
};

const NavItem: React.FC<OwnProps> = (props) => {
	const location = useLocation();
	const className = getActiveClass(location.pathname, props.menu.reactRoute);
	const handleNavClick = () => {
		props.renderSideNav();
		props.toggleIsOpen(props.menu.name);
		if (!props.menu.children) {
			props.hideSidebar();
		}
	};
	return props.menu.children ? (
		<li className={className} onClick={handleNavClick}>
			<Link to={location.pathname}>
				<i className={`fa fa-${icons[props.menu.permissionName]}`} />
				<span className="nav-label">
					<Translate text={props.menu.name} />
				</span>
				<span className="float-right">
					<i
						className={`${
							!!className || props.isOpen
								? "fa fa-chevron-down"
								: "fa fa-chevron-right"
						}`}
					/>
				</span>
			</Link>

			<div className="mini-navbar-links-wrapper">
				<ul className="nav nav-second-level">
					{props.menu.children.map((child) => (
						<li
							key={child.url}
							className={getSubActiveClass(
								location.pathname,
								child.url
							)}
						>
							<Link to={child.url} className="nav-label">
								<Translate text={child.name} />
							</Link>
						</li>
					))}
				</ul>
			</div>

			<Collapse.Collapse
				className={`${className === "active" && "collapse-active"}`}
				isOpened={!!className || props.isOpen}
			>
				<ul className="nav nav-second-level collapse in">
					{props.menu.children.map((child) => (
						<li
							key={child.url}
							className={getSubActiveClass(
								location.pathname,
								child.url
							)}
						>
							<Link
								to={child.url}
								className="nav-label"
								onClick={props.hideSidebar}
							>
								<Translate text={child.name} />
							</Link>
						</li>
					))}
				</ul>
			</Collapse.Collapse>
		</li>
	) : (
		<li className={className} onClick={handleNavClick}>
			<Link to={props.menu.reactRoute}>
				<i className={`fa fa-${icons[props.menu.permissionName]}`} />
				<span className="nav-label">
					<Translate text={props.menu.name} />
				</span>
			</Link>
		</li>
	);
};

export default NavItem;
