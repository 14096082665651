import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import * as actionTypes from 'store/action-types';
import { State } from 'shared/interface';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';
import { onConfirmMessages } from 'shared/constants/messages';
import localizationConstants from 'shared/util/translation/constants';
import { createAction, createLoadingSelector } from 'shared/util/utility';
import { Translate } from '../translate';
import Confirm from '../confirm/confirm';

const TopHeader: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const auth = useSelector((state: State) => state.auth);
	const loading = useSelector((state: State) => loadingSelector(state));

	if (!auth.authData.auth) {
		navigate('/login');
	}
	const toggleModalStatus = () => {
		setIsOpen(!isOpen);
	}
	const onLogout = () => logout();
	const toggleSidebar = () => document.body && document.body.classList.toggle('mini-navbar');
	const logout = () => {
		dispatch(createAction(actionTypes.AUTH_LOGOUT_INIT));
		httpService.get(API_CONFIG.path.logout).then(() => {
			dispatch(createAction(actionTypes.AUTH_LOGOUT_SUCCESS));
		}).catch(() => {
			dispatch(createAction(actionTypes.AUTH_LOGOUT_FAIL));
		});
	}

	return (
		<div className='row border-bottom'>
			<nav
				className='navbar navbar-static-top'
				role='navigation' >
				<div className='navbar-header'>
					<button
						className='navbar-minimalize minimalize-styl-2 btn btn-primary'
						onClick={() => toggleSidebar()}
					>
						<i className='fa fa-bars' />
					</button>
				</div>
				<ul className='nav navbar-top-links navbar-right'>
					<li>
						<div className='logout-btn' onClick={() => toggleModalStatus()}>
							<i className='fa fa-sign-out' />
							<Translate text={localizationConstants.logout} />
						</div>
					</li>
				</ul>
			</nav>
			{isOpen &&
				<Confirm
					show={isOpen}
					handleClose={() => toggleModalStatus()}
					handleConfirm={onLogout}
					loading={loading}
					message={onConfirmMessages.confirmLogout()}
				/>}
		</div>
	)
}
const loadingSelector = createLoadingSelector(['AUTH_LOGOUT']);

export default TopHeader;
