import { allPermissionMapping } from 'shared/constants/constants';
import AuthService from 'shared/services/auth.service';
export interface AllPermissionRequired {
	permission: string[];
}

export interface AnyOnePermissionRequired {
	anyOnePermission?: string[];
}

/**
 * check if user has permission to access any block
 * @param permission : a list of permissions, if user is not having any one permission of the given permissions, return false
 * @param anyOnePermission : a list of optional permissions, if user is having atleast one permission of the given permissions, return true
 */
const checkPermission = (data: AllPermissionRequired | AnyOnePermissionRequired): boolean => {
	const userData = AuthService.getUserData();
	if (!userData || !userData.user || !userData.user.permissions) {
		return false;
	}
	const anyOnePermissionData = data as AnyOnePermissionRequired;
	if (anyOnePermissionData.anyOnePermission) {
		// if user is having at-least one permission of the given permissions, return true
		for (let i = 0; i < anyOnePermissionData.anyOnePermission.length; i += 1) {
			if (userData.user.permissions[allPermissionMapping[anyOnePermissionData.anyOnePermission[i]]]) {
				return true
			}
		}
		return false;
	}
	const allPermissionData = data as AllPermissionRequired;

	// if user is not having any one permission of the given permissions, return false
	for (let i = 0; i < allPermissionData.permission.length; i += 1) {
		if (!userData.user.permissions[allPermissionMapping[allPermissionData.permission[i]]]) {
			return false;
		}
	}
	return true;
}

export {
	checkPermission
}
