import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router';

import Button from '../form/button';
import Breadcrumbs from './breadcrumbs';
import { Translate } from 'shared/components/translate';
import localizationConstants from 'shared/util/translation/constants';

interface Props {
	title: string;
	text?: string;
	linkText?: { name: string; link: string };
	data: { name: string; link: string; }[];
	provideBackBtn?: boolean;
	children?: ReactNode;
}

const Header: React.FC<Props> = (props) => {
	const navigate = useNavigate();

	return (
		<div>
			<div className='row wrapper border-bottom white-bg page-heading'>
				<Breadcrumbs {...props} />
				<div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
					{props.provideBackBtn &&
						<div className='breadcrumb-btn'>
							<Button className='back-btn width-100px' onClick={() => navigate(-1)} btnType='danger'>
								<Translate text={localizationConstants.backBtnText} />
							</Button>
						</div>}
					{props.children}
				</div>
			</div>
		</div>
	)
}
export default Header;
