import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { Translate } from 'shared/components/translate';
import localizationConstants from 'shared/util/translation/constants';
import { formatDate } from 'shared/util/utility';
import Button from 'shared/components/form/button';
import i18n from 'shared/util/localization';
import { enGB, de } from 'date-fns/locale';
import 'react-date-range/dist/styles.css'; // main style file
import { addDays, addMonths, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';

const getCurrentLang = () => localStorage.getItem('lang') === "en" ? enGB : de;

interface DateRangeProps {
	showTitle?: boolean;
	startDate: moment.Moment;
	hideStaticRange?: boolean;
	restrictMaxDate?: boolean;
	lableFormat?: string;
	selectText?: string;
	applySameInitialRange?: boolean;
	endDate: moment.Moment;
	handleSubmit: (range: any) => void;
	maxDate?: Date;
}

const formatDateDisplay = (date: moment.Moment, format = 'YYYY-MMM-DD') => formatDate(date, format);
// const maxDate = new Date();
/**
 * defined static ranges date
 */
const defineds = {
	startOfWeek: startOfWeek(new Date()),
	endOfWeek: endOfWeek(new Date()),
	startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
	endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),
	startOfYesterday: startOfDay(addDays(new Date(), -1)),
	endOfYesterday: endOfDay(addDays(new Date(), -1)),
	startOfMonth: startOfMonth(new Date()),
	endOfMonth: endOfMonth(new Date()),
	startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
	endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};
/**
 * preDefined static date selection ranges label
 */
const staticRanges = [localizationConstants.today, localizationConstants.yesterday, localizationConstants.thisWeek, localizationConstants.lastWeek, localizationConstants.thisMonth, localizationConstants.lastMonth, localizationConstants.custom];

/**
 * DateRangePicker - date range component
 */
const DateRangePicker: React.FC<DateRangeProps> = (props) => {
	const [showCalender, setShowCalender] = useState(false);
	const [selectedLabel, setSelectedLabel] = useState<string | undefined>(localizationConstants.thisWeek);
	const [allowDateSelection, setAllowDateSelection] = useState<boolean>(false);
	const [range, setRange] = useState<any>([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	]);

	const mapEnWeekDaysLabel = (value: string) => {
		// date-picker plugin misbehaving in case of english language
		// so mapping it manully for weekdays label
		return i18n.t(localizationConstants[value.toLowerCase()]) || value;
	}

	/**
	 * onShowCalender - manage hide/show of date picker
	 * @param showCalender - cuurent state of date picker
	 */
	const onShowCalender = (showCalender: boolean, allowDateSelection: boolean = false) => {

		setShowCalender(showCalender);
		setAllowDateSelection(allowDateSelection);
		setSelectedLabel(allowDateSelection ? localizationConstants.custom : undefined);
		if (props.hideStaticRange && showCalender) {
			onStaticRange(localizationConstants.custom);
		}
	}

	/**
	 * onStaticRange - set pre defined range selection
	 * @param label - cuurent selected label
	 */
	const onStaticRange = (label: string) => {
		if (!props.hideStaticRange && label === selectedLabel) {
			setShowCalender(false);
			setAllowDateSelection(false);
			return;
		}

		let startDate = moment();
		let endDate = moment();

		if (label === localizationConstants.custom) {
			setSelectedLabel(label);
			setAllowDateSelection(true);
			return;
		}
		let temp = []
		switch (label) {
			case localizationConstants.today:
				temp = [{
					startDate: defineds.startOfToday,
					endDate: defineds.endOfToday,
					key: 'today'
				}];
				break;
			case localizationConstants.yesterday:
				temp = [{
					startDate: defineds.startOfYesterday,
					endDate: defineds.endOfYesterday,
					key: 'selection'
				}];
				break;
			case localizationConstants.thisWeek:
				temp = [{
					startDate: defineds.startOfWeek,
					endDate: defineds.endOfWeek,
					key: 'selection'
				}];
				break;
			case localizationConstants.lastWeek:
				temp = [{
					startDate: defineds.startOfLastWeek,
					endDate: defineds.endOfLastWeek,
					key: 'selection'
				}];
				break;
			case localizationConstants.thisMonth:
				temp = [{
					startDate: defineds.startOfMonth,
					endDate: defineds.endOfMonth,
					key: 'selection'
				}];
				break;
			case localizationConstants.lastMonth:
				temp = [{
					startDate: defineds.startOfLastMonth,
					endDate: defineds.endOfLastMonth,
					key: 'selection'
				}];
				break;
			default:
				temp = [{
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection'
				}];
				break;
		};

		setSelectedLabel(label);
		setAllowDateSelection(false);
		setShowCalender(false);
		setRange(temp)
		const dateRange = {
			start_date: temp[0].startDate,
			end_date: temp[0].endDate
		};
		props.handleSubmit(dateRange);
	}

	/**
	 * handleSelect - set date range selection
	 * @param range - selected date range
	 */
	const handleSelect = (range: any) => {
		setSelectedLabel(localizationConstants.custom);
		setRange([range.selection || range.today]);
	}

	/**
	 * onSubmit - display selected date
	 */
	const onSubmit = () => {
		setShowCalender(false);
		if (isEqual(props.startDate, range[0].startDate) && isEqual(props.endDate, range[0].endDate)) return;
		const dateRange = {
			start_date: range[0].startDate,
			end_date: range[0].endDate
		};
		props.handleSubmit(dateRange);
	}

	useEffect(() => {
		const dateRange = {
			start_date: props.applySameInitialRange ? props.startDate : defineds.startOfWeek,
			end_date: props.applySameInitialRange ? props.endDate : defineds.endOfWeek,
		};
		props.handleSubmit(dateRange);
	}, []);

	return (
		<div className="date-selection-wrapper d-flex">
			{props.showTitle && <label className="date-label">DateRange: </label>}
			<label className="date-range-label" onClick={() => onShowCalender(!showCalender)}>
				{formatDateDisplay(props.startDate, props.lableFormat)} - {formatDateDisplay(props.endDate, props.lableFormat)}
				<i className="fa fa-caret-down" />
			</label>
			<div className={`date-preview-wrapper ${!showCalender ? 'hide' : ''}`}>
				{!props.hideStaticRange && <div className='static-ranges'>
					<ul>
						{staticRanges.map((label, index) => <li key={index} className={selectedLabel === label ? 'active' : ''} onClick={() => onStaticRange(label)}>
							<Translate text={label} />
						</li>)}
					</ul>
				</div>}
				{allowDateSelection &&
					<div className="date-range-wrapper">
						<DateRange
							moveRangeOnFirstSelection={false}
							onChange={item => handleSelect(item)}
							ranges={range}
							showDateDisplay={false}
							weekdayDisplayFormat={'EEEEEE'}
							maxDate={props.maxDate || new Date()}
							fixedHeight={true}
							locale={getCurrentLang()}
						/>
						<div className="date-range-footer">
							<div className='date-range-actions d-flex align-items-center justify-content-center'>
								<Button
									btnType='danger'
									onClick={() => onShowCalender(false, false)}
									type='button'
									className='mr-2'>{localizationConstants.cancel}</Button>
								<Button
									onClick={() => onSubmit()}
									btnType='primary'>{props.selectText ? props.selectText : localizationConstants.submit}</Button>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	)
}

export default DateRangePicker;
