import React, { Component } from 'react';
import { Translate } from 'shared/components/translate';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';

const CLS_FILLED = ' filled';
const HIDDEN = ' hidden';
const PATTERN_SPECIAL_CHARS = /[!@#$%^&*(),.?":{}|<>]/g; // allowed characters
const PWD_CONFIG: any = {
  // amount_lowercase: 1,
  amount_uppercase: 1,
  amount_numbers: 1,
  amount_special_characters: 1,
  string_length: 8,
}

export const checkPasswordConstraint = (passwordConstraintKey: string, string: string) => {
  const passwordConstraints = PWD_CONFIG;
  let returnVal = false;

  if (string) {
    switch (passwordConstraintKey) {
      case 'all':
        returnVal = (!passwordConstraints.string_length || string.length >= passwordConstraints.string_length) &&
          (!passwordConstraints.amount_lowercase || countLowercase(string) >= passwordConstraints.amount_lowercase) &&
          (!passwordConstraints.amount_uppercase || countUppercase(string) >= passwordConstraints.amount_uppercase) &&
          (!passwordConstraints.amount_numbers || countNumbers(string) >= passwordConstraints.amount_numbers) &&
          (!passwordConstraints.amount_special_characters || countSpecialCharacters(string) >= passwordConstraints.amount_special_characters) &&
          getUnknownCharacters(string) === '';
        break;

      case 'string_length':
        returnVal = string.length >= passwordConstraints.string_length;
        break;

      case 'amount_lowercase':
        returnVal = countLowercase(string) >= passwordConstraints.amount_lowercase;
        break;

      case 'amount_uppercase':
        returnVal = countUppercase(string) >= passwordConstraints.amount_uppercase;
        break;

      case 'amount_numbers':
        returnVal = countNumbers(string) >= passwordConstraints.amount_numbers;
        break;

      case 'amount_special_characters':
        returnVal = countSpecialCharacters(string) >= passwordConstraints.amount_special_characters;
        break;

      default:
        returnVal = false;
    }
  }

  return returnVal;
}

const getUnknownCharacters = (string: string) => {
  let unknownCharacters: Array<any> = [];

  if (string && string.length > 0) {
    let cleanString = string;
    cleanString = cleanString.replace(/[a-z]/g, ""); // remove all lowercase letters
    cleanString = cleanString.replace(/[A-Z]/g, ""); // remove all uppercase letters
    cleanString = cleanString.replace(/\d/g, ""); // remove all numbers
    cleanString = cleanString.replace(PATTERN_SPECIAL_CHARS, ""); // remove all predefined special characters


    let lengthBefore = cleanString.length;
    cleanString = cleanString.split(' ').join(''); // remove all whitespaces
    let lengthAfter = cleanString.length;
    if (lengthAfter < lengthBefore && !unknownCharacters.includes('space')) {
      unknownCharacters.push('space');
    }

    for (let i = 0; i < cleanString.length; i++) {
      if (!unknownCharacters.includes(cleanString.charAt(i))) {
        unknownCharacters.push(cleanString.charAt(i));
      }
    }
  }

  return unknownCharacters.join(' ');
}

const countLowercase = (string: string) => {
  let lowercase = string.match(/[a-z]/g);
  return lowercase ? lowercase.length : 0;
}

const countUppercase = (string: string) => {
  let uppercase = string.match(/[A-Z]/g);
  return uppercase ? uppercase.length : 0;
}

const countNumbers = (string: string) => {
  let numbers = string.match(/\d/g);
  return numbers ? numbers.length : 0;
}

const countSpecialCharacters = (string: string) => {
  let specialCharacters = string.match(PATTERN_SPECIAL_CHARS);
  return specialCharacters ? specialCharacters.length : 0;
}

const PasswordAssistent = (props: any) => {
  const { value } = props;
  const locCons: any = localizationConstants;

  if (value.length > 0) {
    let output = [];
    let unknownCharacters = '';
    let allConstraintsFulfilled = false;
    let passwordVarKeys = Object.keys(PWD_CONFIG);

    output = passwordVarKeys.map((key, index) => {
      return (<tr id={'password-constraint-' + key} key={'password-constraint-' + key} className="password-constraint-line">
        <td className={'password-constraint-icon'}><i className={'fa fa-check font-success ' + (checkPasswordConstraint(key, value) ? '' : HIDDEN)} /></td>
        <td>
          {
            PWD_CONFIG[key] === 1 ?
              <Translate text={(locCons['pwd_s_' + key])} />
              :
              i18n.t(locCons['pwd_p_' + key], { count: PWD_CONFIG[key] }) as string
          }
        </td>
      </tr>)
    });

    unknownCharacters = getUnknownCharacters(value);

    allConstraintsFulfilled = checkPasswordConstraint('all', value);

    return (
      <div>
        {/*<Popover
          trigger="focus"
          placement="bottom"
          target="password"
          className={ allConstraintsFulfilled ? 'all-fulfilled' : 'open-constraints' }
        >
          <PopoverBody>
            <table id="password-popover-content">
              <tbody>
                { unknownCharacters !== '' ?
                  <tr>
                    <td id="password-constraint-0" className={'password-constraint-icon '}>
                      <i className={'fa fa-exclamation-triangle font-danger '} />
                    </td>
                    <td className={'font-danger '}>{unknownCharacters}<Trans i18nKey="auth.not_allowed"/></td>
                  </tr>
                  : null
                }
                { output }
              </tbody>
            </table>
          </PopoverBody>
          <span className={'password-constraint-icon-all-container ' + (allConstraintsFulfilled ? '' : HIDDEN)}>
            <div>
              <i size="lg" className={'fa fa-check-circle font-success password-constraint-icon-all '} />
            </div>
          </span>
        </Popover>*/}
        <div className={`password-assistent ${allConstraintsFulfilled ? 'all-fulfilled' : 'open-constraints'}`}>
          <table id="password-popover-content">
            <tbody>
              {unknownCharacters !== '' ?
                <tr className="password-constraint-line">
                  <td id="password-constraint-0" className={'password-constraint-icon '}>
                    <i className={'fa fa-exclamation-triangle font-danger '} />
                  </td>
                  <td className={'font-danger '}>{unknownCharacters}<Translate text={locCons.not_allowed} /></td>
                </tr>
                : null
              }
              {output}
            </tbody>
          </table>
          <span className={'password-constraint-icon-all-container ' + (allConstraintsFulfilled ? '' : HIDDEN)}>
            <div className="all-checked-icon-container">
              <i className={'fa fa-check-circle font-success password-constraint-icon-all '} />
            </div>
          </span>
        </div>
      </div>
    )
  }
  return null;
}

export default PasswordAssistent
