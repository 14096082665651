import React, { useState } from 'react';

import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';

import { ForgetPasswordParams } from '../interface/company';
import ForgetPasswordForm from '../component/forgetPasswordForm';

const ForgetPassword: React.FC = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isSuccessfull, setIsSuccessfull] = useState(false);

	const forgetPassword = (data: ForgetPasswordParams) => {
		setIsLoading(true);
		HttpService.post(API_CONFIG.path.phoenixForgotPassword, data).then((response) => {
			setIsLoading(false);
			setIsSuccessfull(true);
		}).catch((err: Error) => {
			setIsLoading(false);
		});
	}

	return (
		<div className='gray-bg phoenix-password-wrapper forget-password-wrapper phoenix-background-svg'>
			<ForgetPasswordForm
				forgetPassword={forgetPassword}
				isLoading={isLoading}
				isSuccessful={isSuccessfull}
			/>
		</div>
	)
}

export default ForgetPassword;
