import { combineReducers } from 'redux';
import login from 'features/login/store/login.reducer';
import loadingReducer from './loadingReducer';
import usersReducer from 'features/users/store/users.reducer';
import permissionsReducer from 'features/permissions/store/permissions.reducer';
import organizationsReducer from 'features/organizations/store/organizations.reducer';
import licenseReducer from 'features/customer-license/store/customerLicense.reducer';
import licenseModuleReducer from 'features/licenseModule/store/module.reducer';
import rolesReducer from 'features/roles/store/roles.reducer';
import incomingLicensesReducer from 'features/licenseService/incomingLicenses/store/incomingLicenses.reducer';
import orgIncomingLicensesReducer from 'features/organizations/orgIncomingLicenses/store/orgIncomingLicenses.reducer';
import phoenixIncomingLicensesReducer from 'features/organizations/phoenixLicenses/store/phoenixLicenses.reducer';
import jumpbirdIncomingLicensesReducer from 'features/organizations/jumpbirdLicenses/store/jumpbirdLicenses.reducer';
import machineListReducer from 'features/licenseService/machineList/store/machineList.reducer';
import autoUpdateMachineListReducer from 'features/licenseService/autoUpdateMachineList/store/machineList.reducer';
import licenseNumberReducer from 'features/licenseService/licenseNumbers/store/licenseNumbers.reducer';
import orderCodeExtensionsReducer from 'features/licenseService/orderCodeExtension/store/orderCodeExtensionsList.reducer';
import configurationReducer from 'features/licenseService/configuration/store/configuration.reducer';
import credentialReducer from 'features/birdhub/credential/store/credential.reducer';
import companyReducer from 'features/phoenix/company/store/company.reducer';
import jumpbirdCompanyReducer from 'features/jumpbird/company/store/company.reducer';
import resigoMachinesReducer from 'features/resigo/machine/store/machine.reducer';
import resigoLicensesReducer from 'features/resigo/license/store/license.reducer';
import moduleReducer from 'features/phoenix/module/store/module.reducer';
import packageReducer from 'features/phoenix/package/store/package.reducer';
import jumpbirdPackageReducer from 'features/jumpbird/package/store/package.reducer';
import phoenixApiKeyList from 'features/phoenix/apiKeyList/store/apiKeyList.reducer';
import tseDeviceListReducer from 'features/tseDevice/store/tseDevice.reducer';
import tseCustomerListReducer from 'features/tseCustomer/store/tseCustomer.reducer';
import reportListReducer from 'features/provision/store/report.reducer';
import turnoverReducer from 'features/turnoverStatistics/store/turnover.reducer';
import invoiceGroupReducer from 'features/invoices/store/invoiceGroup.reducer';
import inventoryGroupReducer from 'features/inventory/inventoryGroup/store/inventoryGroup.reducer';
import inventoriesReducer from 'features/inventory/inventories/store/inventories.reducer';
import { State, Action } from 'shared/interface';

import * as actionTypes from 'store/action-types';

const appReducer = combineReducers({
	auth: login,
	loading: loadingReducer,
	users: usersReducer,
	permission: permissionsReducer,
	role: rolesReducer,
	organizations: organizationsReducer,
	customerLicense: licenseReducer,
	licenseModule: licenseModuleReducer,
	incomingLicenses: incomingLicensesReducer,
	orgIncomingLicenses: orgIncomingLicensesReducer,
	phoenixIncomingLicenses: phoenixIncomingLicensesReducer,
	jumpbirdIncomingLicenses: jumpbirdIncomingLicensesReducer,
	machineList: machineListReducer,
	licenseNumber: licenseNumberReducer,
	orderCoseExtension: orderCodeExtensionsReducer,
	configurationsList: configurationReducer,
	credential: credentialReducer,
	company: companyReducer,
	jumpbirdCompany: jumpbirdCompanyReducer,
	resigoMachines: resigoMachinesReducer,
	resigoLicenses: resigoLicensesReducer,
	module: moduleReducer,
	package: packageReducer,
	jumpbirdPackage: jumpbirdPackageReducer,
	phoenixApiKeyList: phoenixApiKeyList,
	autoUpdateMachineList: autoUpdateMachineListReducer,
	tseDevice: tseDeviceListReducer,
	tseCustomer: tseCustomerListReducer,
	partnerReport: reportListReducer,
	turnover: turnoverReducer,
	invoiceGroup: invoiceGroupReducer,
	inventoryGroup: inventoryGroupReducer,
	inventories: inventoriesReducer,
});

const rootReducer = (state: State | undefined, action: Action): State => {
	if (action.type === actionTypes.AUTH_LOGOUT_SUCCESS || action.type === actionTypes.AUTH_LOGOUT_FAIL) {
		localStorage.removeItem('authData');
		localStorage.removeItem('userData');
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
